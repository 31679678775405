import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import parse from "html-react-parser";
import "./MediaCredits.scss";
import { useQuery } from "@apollo/client";
import { GET_ACTIVE_MEDIA_URL } from "../../../queries";
import { getUsedVideos } from "../../../utilities";

const MediaCredits = ({ galleryImages, videoData }) => {
  const [mediaType, setMediaType] = useState();
  const { data } = useQuery(GET_ACTIVE_MEDIA_URL);
  const mediaUrl = data.activeMediaUrl;

  const credit = useMemo(() => {
    const videos = getUsedVideos(videoData);
    let imgs = galleryImages && galleryImages.length ? galleryImages : [];

    if (!(imgs && imgs.length) && !(videos && videos.length)) {
      return { de: "", en: "" };
    }

    const allData = [...imgs, ...videos];
    let type;

    const mediaItem = allData.find((x) => {
      if (x.mediaItemUrl === mediaUrl) {
        type = "img";
        return true;
      }

      if (x.videoUrl === mediaUrl) {
        type = "video";
        return true;
      }
      return false;
    });

    if (mediaItem) {
      let copy =
        type === "img"
          ? mediaItem.mediaTexts.copyright
          : mediaItem.videoCopyright;

      let artist =
        type === "img" ? mediaItem.mediaTexts.artist : mediaItem.artist;

      setMediaType(type);
      let combinedCopy = `${artist || ""} ${copy ? "&copy;&nbsp;" : ""}${
        copy || ""
      }`;

      if (combinedCopy)
        combinedCopy = combinedCopy
          .replace(/(<([^>]+)>)|(\r\n|\n|\r)/gi, "")
          .trim();

      return combinedCopy;
    }

    return;
  }, [mediaUrl, galleryImages, videoData]);

  return credit && credit.length ? (
    <div className="MediaCredits">
      <FormattedMessage
        id={`mediaCredits.headline.${mediaType}`}
        defaultMessage="Foto:"
      />
      <span className="credit" key={credit}>
        <span>&nbsp;{parse(credit)}</span>
      </span>
    </div>
  ) : null;
};

export default MediaCredits;
